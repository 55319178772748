// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-homepage-js": () => import("./../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-inspiration-js": () => import("./../src/templates/inspiration.js" /* webpackChunkName: "component---src-templates-inspiration-js" */),
  "component---src-templates-shop-js": () => import("./../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-collection-js": () => import("./../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-guide-js": () => import("./../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-samples-js": () => import("./../src/templates/samples.js" /* webpackChunkName: "component---src-templates-samples-js" */),
  "component---src-templates-case-js": () => import("./../src/templates/case.js" /* webpackChunkName: "component---src-templates-case-js" */),
  "component---src-pages-aluproff-order-js": () => import("./../src/pages/aluproff-order.js" /* webpackChunkName: "component---src-pages-aluproff-order-js" */),
  "component---src-pages-auth-js": () => import("./../src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-calendar-js": () => import("./../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-inspiration-js": () => import("./../src/pages/inspiration.js" /* webpackChunkName: "component---src-pages-inspiration-js" */),
  "component---src-pages-installation-confirmation-js": () => import("./../src/pages/installation-confirmation.js" /* webpackChunkName: "component---src-pages-installation-confirmation-js" */),
  "component---src-pages-installation-overview-js": () => import("./../src/pages/installation-overview.js" /* webpackChunkName: "component---src-pages-installation-overview-js" */),
  "component---src-pages-internal-project-js": () => import("./../src/pages/internal-project.js" /* webpackChunkName: "component---src-pages-internal-project-js" */),
  "component---src-pages-project-js": () => import("./../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-purchase-order-js": () => import("./../src/pages/purchase-order.js" /* webpackChunkName: "component---src-pages-purchase-order-js" */),
  "component---src-pages-shop-js": () => import("./../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-styleraad-js": () => import("./../src/pages/styleraad.js" /* webpackChunkName: "component---src-pages-styleraad-js" */),
  "component---src-pages-styleradgivning-js": () => import("./../src/pages/styleradgivning.js" /* webpackChunkName: "component---src-pages-styleradgivning-js" */),
  "component---src-pages-tilbud-js": () => import("./../src/pages/tilbud.js" /* webpackChunkName: "component---src-pages-tilbud-js" */)
}

